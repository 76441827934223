import { useState } from "react";
import "./contact.scss";
import emailjs from "emailjs-com"

export default function Contact() {
  function sendEmail(e) {
    e.preventDefault();  

    emailjs.sendForm('service_97nl2lr', 'template_vpx3r1i', e.target, 'wpwrcF5i84g7VU_WH')
      .then((result) => {
          window.location.reload()  
      }, (error) => {
          console.log(error.text);
      });

  };

  return (
    <div className="contact" id="contact">
      <div className="left">
      <img src="assets/Contact Layover.gif" alt=""/> 
      </div>
      <div className="right">
        <form className="contact-form" onSubmit={sendEmail}>
        <label>Name</label>
        <input type="text" name="from_name" required /> <br/>
        <label>Email</label>
        <input type="email" name="from_email" required /> <br/>
        <label>Subject</label>
        <input type="text" name="subject" required /> <br/>
        <label>Message</label>
        <textarea name="message" required /> <br/>
        <input type="submit" value="Send"/>
      </form>
      </div>
      
    </div>
  );
}