import React from 'react'
import "./works.scss"
import { useState } from "react";

export default function Works() {
  const [currentSlide,setCurrentSlide]= useState(0);

  const data = [
    {
      id:"1",
      icon: "./assets/youtube.png",
      title:"Solace Music Video",
      desc:"We are living in tough times, where the world is experiencing pain, stress, and discomfort more and more, every day. In this rapidly developing negativity, I wanted to do something positive. \n \n Solace, is a musical personification of peace and calmness, which I envisioned as I put together these notes that strike hope and assurance.",
      iframe:"https://www.youtube.com/embed/-jFVj_SUqzk",
    },
    {
      id:"2",
      icon: "./assets/youtube.png",
      title:"Samay Ka Khatola Music Video",
      desc:"Samay Ka Khatola is a beautiful representation of childhood, its beautiful memories, and the journey of growing up. When the age settles in, along with maturity, responsibilities, and the burdens of life, a realization blooms in the heart, making us understand the preciousness of being young and free, truly, as a child. We are all capable of being a kid at heart, but we have struggled many times to embrace it. May this song bring back many memories and give you one more reason to relive them and become the kid at heart that you truly are. \n \n Music produced, mixed, and mastered by Backpack Lights \n \n Vocals by Zeena Macwan \n \n Video produced by Backpack Lights \n \n Written by Zubin Macwan, Zeena Macwan & Francis Macwan",
      iframe:"https://www.youtube.com/embed/svte7BjSHVc",
    },
    {
      id:"3",
      icon: "./assets/youtube.png",
      title:"Absolution | 4k",
      desc:"A song that has no rhythm in it, yet it moves. It has no words in it, yet it speaks. Absolution is an experience, it is a resolution of emotions, the calm in a storm. It stands for a feeling, a feeling unexplainable \n \n Music and Video made in FL Studio.",
      iframe:"https://www.youtube.com/embed/R14t4-D34tI",
    },
  ]

  const handleClick = (way)=>{
    way === "left" ? setCurrentSlide(currentSlide > 0 ? currentSlide-1 : 2 ) : 
    setCurrentSlide(currentSlide<data.length-1 ?currentSlide+1 : 0);
  };
  return (
    <div className='works' id='works'>
      <div className='slider' style={{transform:`translateX(-${currentSlide *100}vw)`}}>
      {data.map(d=>(
        <div className='container'>
          <div className='item'>
            <div className="left">
              <div className="leftContainer">
                <div className="imgContainer">
                  <img src={d.icon} alt="" />
                </div>
                <h2>{d.title}</h2>
                <p>{d.desc}</p>
              <span></span>
              </div>
            </div>
            <div className="right">
            <iframe src={d.iframe}></iframe>
            </div>
          </div>
        </div>))}
      </div>
      <img src="assets/down.png" alt="" className='arrow left' onClick={()=>handleClick("left")}/>        
      <img src="assets/down.png" alt="" className='arrow right'onClick={()=>handleClick("right")}/>
    </div>
    
  );
}
export {Works};