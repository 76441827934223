import React from 'react'
import "./intro.scss"
import Typewriter from 'typewriter-effect';

export default function Intro() {

  return (
    <div className='intro' id='intro'>
        <div className="left">
        <a href="https://linktr.ee/BackpackLights" target="_blank" rel="noopener noreferrer">
          <div className="imgContainer">
                <img src="assets/IntroPageMe_gif.GIF" alt="" />      
            </div>
        </a>   
      </div>
        <div className="right">
          <div className="wrapper">
            <h2>Hi there, I'm</h2>
            <h1>Zubin Macwan</h1>
            <h3>
              <span>
              <Typewriter 
           
           options={{ 
           strings:["MBA Candidate", "Web Developer", "Graphic Designer", "Music Producer"],
           autoStart:true,
           delay:50,
           loop:true
           }}
           
           /> 
              </span>
          </h3>
          </div>
        </div>
      </div>
  )
}
