import "./portfolio.scss"

export default function Portfolio() {


  return (
    <div className='portfolio' id='portfolio'>
      <span class="text1"> 
        Winning Music Projects
      </span>

      <div className="container">
        <div class="cards-list">

          <div class="card 1">
            <div class="card_image">
              <a href="https://metapop.com/officialbackpacklights/tracks/candid-cans/248778" target="_blank" rel="noopener noreferrer">
              <img 
                src="assets/candid cans_gif.gif" alt='Candid Cans' />
              </a>
            </div>
          </div>

          <div class="card 2">
            <div class="card_image">
              <a href="https://metapop.com/officialbackpacklights/tracks/maayie/206032" target="_blank" rel="noopener noreferrer">
                <img src="assets/Maayie.gif"/>
              </a>
   
            </div>
          </div>

          <div class="card 3">
            <div class="card_image">
              <a href="https://ifp.world/previous-winners-music/#:~:text=Choti%20Choti%20Khushi,Leader%20%2D%20Zubin%20Macwan" rel="noopener noreferrer">
                <img src="assets/ifp.gif" target="_blank" rel="noopener noreferrer"/>
              </a>
            </div>
            <div class="card_title">
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
